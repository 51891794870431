<template>
  <div style="">
    <portal to="title-page">
      <h1>Listado de reservaciones por dia</h1>
    </portal>
    <v-data-table v-if="items != null"
      v-model="selected"
      :headers="tableHeaders"
      :items="items"
      item-key="id"
      :search="search"
      fixed-header
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:top>
        <div class="table-top-bar">
          <v-row>
            <v-col
              cols="12"
              sm="4"
              md="3"
            >
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Fecha"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-btn class="mt-md-3" small @click="searchReservations()"
                elevation="2"
                color="primary"
              ><v-icon>mdi-magnify</v-icon> Buscar</v-btn>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
      </template>
      <template v-slot:item.confirmed="{ item }">
        <v-icon 
          v-if="!item.confirmationInfo"
          class=""
          @click.stop="openModalConfirmationInfo(item)"
        >
          {{mdiCheckboxBlankCircleOutline}}
        </v-icon>
        <v-icon 
          v-if="item.confirmationInfo"
          class="success--text"
        >
          {{ mdiCheckCircle }}
        </v-icon>
        <br>
        <template v-if="item.pickupDateTime">
          {{$moment(item.pickupDateTime.seconds * 1000).format('HH:mma')}}
        </template>
      </template>
      <template v-slot:item.code="{ item }">
        {{item.code}}
      </template>
      <template v-slot:item.pax="{ item }">
        <div v-if="item.adults > 0">Adultos: {{item.adults}}</div>
        <div v-if="item.elderlies > 0">A.Mayores: {{item.elderlies}}</div>
        <div v-if="item.children > 0">Niños: {{item.children}}</div>
        <div v-if="item.infants > 0">Infantes: {{item.infants}}</div>
      </template>
      <template v-slot:item.actions="{ item }">
        <a :href="`/backend/divers/reservations/${item.id}`" style="text-decoration: none" @click="redirectTo(`/backend/divers/reservations/${item.id}`, $event)">
          <v-icon 
            class="mr-2"
          >
            mdi-pencil
          </v-icon>
        </a>
      </template>
      <template v-slot:item.status="{ item }">
        <BtnStatus :icon="mdiPencil" :status="item.status" v-on:click.native="test"/>
      </template>
      <template  v-slot:item.paymentStatus="{ item }">
        <BtnPaymentStatus :icon="mdiPencil" :status="item.paymentStatus" />
      </template>
      <template v-slot:item.total="{ item }">
        ${{item.total}}<small>{{item.currency}}</small>
      </template>
    </v-data-table>
    <ConfirmationInfo v-if="reservation"  :reservation="reservation"/>
  </div>
</template>
<script>
    import Reservation from "@/models/Reservation";
    import ConfirmationInfo from "@/components/reservations/ConfirmationInfo";
    import BtnStatus from "@/components/reservations/BtnStatus";
    import BtnPaymentStatus from "@/components/reservations/BtnPaymentStatus";
    import { mdiPencil, mdiCheckboxBlankCircleOutline, mdiCheckCircle } from '@mdi/js';
    import firebase from 'firebase/app';
    import 'firebase/auth';

    export default {
      name:'DiversReservationsList',
      components:{ConfirmationInfo, BtnStatus, BtnPaymentStatus},
      
      data(){
        let date = this.$moment()//.add(1, 'day');
        return {
          mdiPencil:mdiPencil,
          mdiCheckboxBlankCircleOutline:mdiCheckboxBlankCircleOutline,
          mdiCheckCircle:mdiCheckCircle,
          date:date.format('YYYY-MM-DD'),
          reservationModel:new Reservation,
          items:null,
          unsubscribe:null,
          search:'',
          panel:'',
          selected:[],
          loading:true,
          tableHeaders:[
            {
              text: 'Pickup',
              align: 'center',
              sortable: true,
              value: 'confirmed',
            },
            {
              text: 'ID',
              align: 'start',
              sortable: true,
              value: 'code',
              width:"140px"
            },
            {
              text: 'Pax',
              align: 'start',
              sortable: true,
              value: 'pax',
            },
            {
              text: 'Status',
              align: 'start',
              sortable: true,
              value: 'status',
            },
            {
              text: 'Pago',
              align: 'start',
              sortable: true,
              value: 'paymentStatus',
            },
            {
              text: 'Total',
              align: 'center',
              sortable: true,
              value: 'total',
            },
            { text: 'Actions', value: 'actions', sortable: false },
        ],
        menu2: false,
        dialog: false,
        reservation: null,
      };
      },
      created(){
        this.$store.commit('SET_LAYOUT', 'Divers')
      },
      mounted(){
        this.searchReservations();
      },
      methods:{
        searchReservations() {
          if (this.unsubscribe) {
            this.unsubscribe();
          }
          this.loading = true;
          let currentUser = firebase.auth().currentUser;
          this.unsubscribe = this.reservationModel.findByDate(this.$moment(this.date)).onSnapshot(snap => {
            this.items = [];
            snap.forEach(doc => {
              if (doc.data().panel == 'divers') {
                if(currentUser.uid == doc.data().userUID){
                  this.items.push(doc.data());
                }
              }
            })
            this.loading = false;
          })
        },
        openModalConfirmationInfo(reservation) {
          this.reservation = reservation
          this.$store.dispatch('reservation/setDialogConfirmationInfo', true)
        },
        redirectTo(url, event) {
          if (event) {
            event.preventDefault();
          }
          this.$router.push({path:url})
        }
      },
      watch: {
        panel: function() {
          this.searchReservations()
        }
      }
    }
</script>
